/* Tablets and under */
@media (max-width: 768px) {
  .grid,
  .showcase .grid,
  .stats .grid,
  .cli .grid,
  .cloud .grid,
  .features-main .grid,
  .portfolio-main .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .showcase {
    height: auto;
  }

  .showcase-text {
    text-align: center;
    margin-top: 40px;
    animation: slideInFromTop 1s ease-in;
  }

  .showcase-form {
    justify-self: center;
    margin: auto;
    animation: slideInFromBottom 1s ease-in;
  }

  .cli .grid > *:first-child {
    grid-column: 1;
    grid-row: 1;
  }

  .features-head,
  .features-sub-head,
  .docs-head {
    text-align: center;
  }

  .features-head img,
  .features-sub-head img,
  .docs-head img {
    justify-self: center;
  }

  .portfolio-main .grid > *:first-child,
  .portfolio-main .grid > *:nth-child(2) {
    grid-column: 1;
  }
}

/* Mobile */
@media (max-width: 500px) {
  .navbar {
    height: 110px;
  }

  .navbar .flex {
    flex-direction: column;
  }

  .navbar ul {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .showcase-form {
    width: 300px;
  }
}
