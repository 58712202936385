@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap");

:root {
  --primary-color: #1a2930;
  --secondary-color: #c5c1c0;
  --dark-color: #0a1612;
  --light-color: #f7ce3e;
  --success-color: #5cb85c;
  --error-color: #d9534f;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Mukta", sans-serif;
  color: #333;
  line-height: 1.6;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

h1,
h2 {
  font-weight: 300;
  line-height: 1.2;
  margin: 10px 0;
}

p {
  margin: 10px 0;
}

img {
  width: 100%;
}

code,
pre {
  background: #333;
  color: #fff;
  padding: 10px;
}

.hidden {
  visibility: hidden;
  height: 0;
}

/* HeaderNav */
.headernav {
  background-color: var(--primary-color);
  color: #fff;
  height: 70px;
}

.headernav ul {
  display: flex;
}

.headernav a {
  color: #fff;
  padding: 10px;
  margin: 0 5px;
}

.headernav a:hover {
  border-bottom: 2px #fff solid;
}

.headernav .flex {
  justify-content: space-between;
}

/* Showcase */
.showcase {
  height: 400px;
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}

.showcase h1 {
  font-size: 40px;
}

.showcase p {
  margin: 20px 0;
}

.showcase .grid {
  overflow: visible;
  grid-template-columns: 55% auto;
  gap: 30px;
}

.showcase-text {
  animation: slideInFromTop 0.8s ease-in;
}

.about-headshot {
  position: relative;
  top: 60px;
  height: 350px;
  width: 250px;
  padding: 40px;
  z-index: 100;
  justify-content: center;
  justify-self: flex-end;
  animation: slideInFromRight 0.8s ease-in;
}

.about-headshot img {
  /* width: 200px; */
  justify-self: flex-end;
}

.showcase-form .form-control {
  margin: 30px 0;
}

.showcase-form input[type="text"],
.showcase-form input[type="email"] {
  border: 0;
  border-bottom: 1px solid #b4becb;
  width: 100%;
  padding: 3px;
  font-size: 16px;
}

.showcase-form input:focus {
  outline: none;
}

.showcase::before,
.showcase::after {
  content: "";
  position: absolute;
  height: 100px;
  bottom: -70px;
  right: 0;
  left: 0;
  background: #fff;
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
}

/* Stats */
.stats {
  padding-top: 100px;
  animation: slideInFromBottom 1s ease-in;
}

.stats-heading {
  max-width: 500px;
  margin: auto;
}

.stats .grid h3 {
  font-size: 35px;
}

.stats .grid p {
  font-size: 20px;
  font-weight: bold;
}

/* Cli */
.cli .grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.cli .grid > *:first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

/* Cloud */
.cloud .grid {
  grid-template-columns: 4fr 3fr;
}

/* Languages */

.languages .flex {
  flex-wrap: wrap;
}

.languages .card {
  text-align: center;
  margin: 18px 10px 40px;
  transition: transform 0.2s ease-in;
}

.languages .card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.portfolio .card:hover {
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
}

/* portfolio */

.portfolio {
  height: 400px;
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}

.portfolio-head img,
.docs-head img {
  width: 200px;
  justify-self: flex-end;
}

.portfolio-sub-head img {
  width: 300px;
  justify-self: flex-end;
}

.portfolio-main .card {
  height: 440px;
  margin-right: 20px;
  text-align: center;
  position: relative;
  z-index: 100;
  justify-content: center;
  justify-self: flex-end;
  /* animation: slideInFromTop 1s ease-in; */
}

.portfolio-main .grid {
  padding: 30px;
}

.portfolio-main img {
  width: 420px;
  width: 310px;
  justify-self: flex-end;
  border-radius: 10px;
  cursor: pointer;
}

.portfolio-main img:hover {
  transform: scale(0.98);
}

.portfolio .card:hover .overlay {
  transition: 0.5s ease;
  background-color: #008cba;
}

.overlay {
  width: 420px;
  width: 310px;
  justify-self: flex-end;
  border-radius: 1rem;
  cursor: pointer;
  transition: 0.5s ease;
  background-color: #008cba;
}

.portfolio::before,
.portfolio::after {
  content: "";
  position: absolute;
  height: 100px;
  bottom: -70px;
  right: 0;
  left: 0;
  background: #fff;
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
}

/* .portfolio-main .grid > *:first-child {
  grid-column: 1 / span 1;
}

.portfolio-main .grid > *:nth-child(2) {
  grid-column: 1 / span 2;
} */

/* Docs */
.docs-main h3 {
  margin: 20px 0;
}

.docs-main .grid {
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
}

.docs-main nav li {
  font-size: 17px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px #ccc solid;
}

.docs-main a:hover {
  font-weight: bold;
}

/* Contact */

.contact::before,
.contact::after {
  content: "";
  position: absolute;
  height: 100px;
  bottom: -70px;
  right: 0;
  left: 0;
  background: #fff;
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
}

/* Footer */
.social a i {
  margin: 0 10px;
  color: #fff;
  justify-content: space-between;
}

.social {
  color: #fff;
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateX(0);
  }
}
